// src/LoadingPage.js
import React, { useEffect, useState } from 'react';

const LoadingPage = ({ onComplete }) => {
  const [startMove, setStartMove] = useState(false); // 控制移动动画的状态变量

  useEffect(() => {
    // 监听动画完成事件
    const timer = setTimeout(() => {
      setStartMove(true); // 开始移动动画
    }, 4000); // 假设动画持续时间为5秒

    return () => clearTimeout(timer); // 清除定时器
  }, []);

  useEffect(() => {
    if (startMove) {
      // 监听移动动画完成事件
      const moveTimer = setTimeout(() => {
        onComplete(); // 移动动画完成后调用onComplete函数
      }, 0); // 假设移动动画持续时间为2秒

      return () => clearTimeout(moveTimer); // 清除移动动画的定时器
    }
  }, [startMove, onComplete]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0px' ,
        height: '130vh',
      }}
    >
      <div></div>
    </div>
  );
};

export default LoadingPage; // 导出LoadingPage组件