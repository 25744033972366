// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Navbar.css';
import logo from './logo.png'; // 确保logo.png在正确的路径

const Navbar = () => {
  const [showBackButton, setShowBackButton] = useState(false);
  const [contentClass, setContentClass] = useState('navbar-content');

  useEffect(() => {
    const handleShowBackButton = () => {
      setContentClass('navbar-content hidden');
      setTimeout(() => {
        setShowBackButton(true);
        setContentClass('navbar-content');
      }, 500); // 渐隐效果完成后显示back按钮
    };
    const handleHideBackButton = () => {
      setContentClass('navbar-content hidden');
      setTimeout(() => {
        setShowBackButton(false);
        setContentClass('navbar-content');
      }, 500); // 渐隐效果完成后显示原始内容
    };

    window.addEventListener('showBackButton', handleShowBackButton);
    window.addEventListener('hideBackButton', handleHideBackButton);

    return () => {
      window.removeEventListener('showBackButton', handleShowBackButton);
      window.removeEventListener('hideBackButton', handleHideBackButton);
    };
  }, []);

  const handleBackClick = () => {
    if (window.handleBackClick) {
      window.handleBackClick();
    }
  };

  const navbarContent = (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className={contentClass}>
        {showBackButton ? (
          <button className="back-button" onClick={handleBackClick}>BACK</button>
        ) : (
          <>
            <a href="#page1">SERVICE</a>
            <a href="#page2">WORK</a>
            <a href="#page3">ABOUT</a>
            <a href="#page4">CONTACT</a>
            <a href="#page5">BLOG</a>
          </>
        )}
      </div>
    </nav>
  );

  return ReactDOM.createPortal(navbarContent, document.body);
};

export default Navbar;
