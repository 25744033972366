// /components/SecurityFeatures.js
import React, { useEffect } from 'react';

const SecurityFeatures = () => {
  useEffect(() => {
    // 禁止右键菜单
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);

    // 禁止图片拖动
    const handleDragStart = (event) => {
      if (event.target.tagName === 'IMG') {
        event.preventDefault();
      }
    };
    document.addEventListener('dragstart', handleDragStart);

    // 禁用部分键盘快捷键
    const handleKeydown = (event) => {
      if (event.ctrlKey && (event.key === 'u' || event.key === 's' || event.key === 'c' || event.key === 'i')) {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeydown);

    // 清理事件监听器
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('dragstart', handleDragStart);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return null; // 这个组件不需要渲染任何内容
};

export default SecurityFeatures;
