// src/pages/Page4.js
import React, { useState } from 'react';
import './Page4.css';
import ProjectPage from './ProjectPage';

const Page4 = () => {
  const [showProject, setShowProject] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const handleClick = (id) => {
    setProjectId(id);
    setShowProject(true);
    const showBackButtonEvent = new Event('showBackButton');
    window.dispatchEvent(showBackButtonEvent);
  };

  const handleBackClick = () => {
    setShowProject(false);
    const hideBackButtonEvent = new Event('hideBackButton');
    window.dispatchEvent(hideBackButtonEvent);
  };

  return (
    <div id="fullpage-page4" className="section">
      <div className="new-content-background"></div>
      <div className="new-section">
        <div className="project">
          <div className="img-container" onClick={() => handleClick(1)}>
            <div className="img-wrapper one">
            </div>
            <div className="titlepng"
            style={{ backgroundImage: 'url(../images/c1.png)' }}
            ></div>
          </div>
        </div>
        <div className="project">
          <div className="img-container" onClick={() => handleClick(2)}>
            <div className="img-wrapper two"></div>
            <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c2.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
          <div className="img-container" onClick={() => handleClick(3)}>
            <div className="img-wrapper three"></div>
            <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c3.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
          <div className="img-container" onClick={() => handleClick(4)}>
            <div className="img-wrapper four"></div>
            <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c4.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
          <div className="img-container" onClick={() => handleClick(5)}>
            <div className="img-wrapper five"></div>
            <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c5.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
        <div className="img-container" onClick={() => handleClick(6)}>
          <div className="img-wrapper six"></div>
          <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c6.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
        <div className="img-container" onClick={() => handleClick(7)}>
          <div className="img-wrapper seven"></div>
          <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c7.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">
        <div className="img-container" onClick={() => handleClick(8)}>
          <div className="img-wrapper eight"></div>
          <div className="titlepng"
              style={{ backgroundImage: 'url(../images/c8.png)' }}
              ></div>
          </div>
        </div>
        <div className="project">

        </div>
      </div>
      {showProject && <ProjectPage id={projectId} onBackClick={handleBackClick} />}
    </div>
  );
};

export default Page4;