// src/App.js
import React, { useState, useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import FullpageWrapper from './FullpageWrapper';
import Navbar from './components/Navbar';
import ProjectPage from './pages/ProjectPage';
import LottieAnimation from './LottieAnimation';
import './App.css';
import './animations.css';

const AppContent = ({ lottieRef }) => {
  const location = useLocation();
  const [loadingComplete, setLoadingComplete] = useState(false);

  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  return (
    <div>
      {loadingComplete && <Navbar location={location} />}
      {loadingComplete ? (
        <Routes>
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/" element={<FullpageWrapper lottieRef={lottieRef} />} />
        </Routes>
      ) : (
        <LoadingPage onComplete={handleLoadingComplete} />
      )}
    </div>
  );
};

const App = () => {
  const lottieRef = useRef(null);

  return (
    <BrowserRouter>
      <AppContent lottieRef={lottieRef} />
      <LottieAnimation ref={lottieRef} />
    </BrowserRouter>
  );
};

export default App;
