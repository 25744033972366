// src/pages/Page2.js
import React, { useState } from 'react';
import './Page2.css';

const Page2 = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="section">
      <div 
        className={`text-container ${isHovered ? 'hover' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-front">
          我们使用 策略、品牌、<br />
          数字设计和互动设计<br />
          来吸引受众。
        </div>
        <div className="text-back">
          We use Strategy, Branding, Digital<br />
          Design & Motion Design to seduce<br />
          your audience.
        </div>
        <div className="adjustable-text cn">
          人们都买的是一种自己有限认知范围内的感觉、知觉、记忆、思维、想像和语言等，而非事实本身，所以我们深度专注品牌信息的传达。
        </div>
        <div className="adjustable-text en">
          People buy based on their perceptions, not the facts. So, we focus deeply on conveying brand information.
        </div>
      </div>
    </div>
  );
};

export default Page2;
