// src/pages/Page3.js
import React, { useEffect } from 'react';
import './Page3.css';

// 初始化动画参数
let currentX1 = 10;
let currentY1 = 20;
let currentX2 = 85;
let currentY2 = 75;
let angle1 = 0; // 用于控制X1Y1的角度
let angle2 = Math.PI; // 用于控制X2Y2的角度

// 动画函数
function animate() {
  let targetX1 = 10 + 10 * Math.sin(angle1) + (Math.random() - 0.5) * 2;
  let targetY1 = 50 + 20 * Math.cos(angle1) + (Math.random() - 0.5) * 5;
  let targetX2 = 90 + 10 * Math.sin(angle2) + (Math.random() - 0.5) * 2;
  let targetY2 = 50 + 20 * Math.cos(angle2) + (Math.random() - 0.5) * 5;

  currentX1 += (targetX1 - currentX1) * 0.05;
  currentY1 += (targetY1 - currentY1) * 0.05;
  currentX2 += (targetX2 - currentX2) * 0.05;
  currentY2 += (targetY2 - currentY2) * 0.05;

  angle1 += 0.01 + (Math.random() - 0.5) * 0.005;
  angle2 += 0.02 + (Math.random() - 0.5) * 0.005;

  const newBackground = `radial-gradient(circle at ${currentX1}% ${currentY1}%, rgba(39, 95, 172, 1), rgba(39, 95, 172, 0) 40%),
                         radial-gradient(circle at ${currentX2}% ${currentY2}%, rgba(39, 95, 172, 1), rgba(39, 95, 172, 0) 40%),
                         #053A95`;

  document.querySelector('.new-content-background').style.background = newBackground;

  requestAnimationFrame(animate);
}

const Page3 = () => {
  useEffect(() => {
    requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(animate);
    };
  }, []);

  return (
    <div id="fullpage-page3" className="section">
      <div className="new-content-background"></div>
      <div className="slide">
        <div className="new-content">
          <div className="new-content-text" id="page1">
            <h1>不做减法的流程设计</h1>
            <h2>In Our Process,<br />We don’t Simplify</h2>
            <div className="new-content-steps animate">
              <span className="dropdown">
                调研
                <div className="dropdown-content">
                  <p>品牌定位分析</p>
                  <p>合作研讨会</p>
                  <p>用户调研</p>
                  <p>市场调查</p>
                  <p>关键见解和建议</p>
                </div>
              </span>
              <span className="dropdown">
                定位
                <div className="dropdown-content">
                  <p>品牌战略诊断</p>
                  <p>核心价值分析</p>
                  <p>核心传播内容</p>
                </div>
              </span>
              <span className="dropdown">
                原型
                <div className="dropdown-content">
                  <p>视觉探索</p>
                  <p>用户体验设计</p>
                  <p>品牌标识设计</p>
                </div>
              </span>
              <span className="dropdown">
                执行
                <div className="dropdown-content">
                  <p>视觉识别系统</p>
                  <p>数字设计</p>
                  <p>Web开发设计</p>
                  <p>内容制作</p>
                  <p>活动执行</p>
                </div>
              </span>
              
              <div className="page3-logo">
            <p>ZHANDIAN<br></br>BRAND & DIGITAL<br></br>
            DESIGN AGENCY</p>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page3;
