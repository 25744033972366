// src/pages/Page1.js
import React, { useEffect } from 'react';
import './Page1.css';

const Page1 = () => {
  useEffect(() => {
    const textElements = document.querySelectorAll('.c-cover__text, .c-cover__text--highlight');
    let currentX = window.innerWidth / 2;

    const handleMouseMove = (e) => {
      const { clientX } = e;
      currentX = clientX;
    };

    const animate = () => {
      textElements.forEach(element => {
        const currentTransform = element.style.transform.match(/translateX\((-?\d+\.?\d*)px\)/);
        const currentTranslateX = currentTransform ? parseFloat(currentTransform[1]) : 0;
        const targetTranslateX = currentX - window.innerWidth / 2;
        const nextTranslateX = currentTranslateX + (targetTranslateX - currentTranslateX) * 1.5;
        element.style.transform = `translateX(${nextTranslateX}px)`;
      });
      requestAnimationFrame(animate);
    };

    if (!isMobileDevice()) {
      window.addEventListener('mousemove', handleMouseMove);
      requestAnimationFrame(animate);
    } else {
      const animateMobile = () => {
        textElements.forEach(element => {
          element.style.transform = `translateX(${(window.innerWidth + element.offsetWidth)}px)`;
        });
        setTimeout(() => {
          textElements.forEach(element => {
            element.style.transition = 'transform 20s linear';
            element.style.transform = `translateX(-${element.offsetWidth}px)`;
          });
        }, 100); // Small delay to start the animation
      };
      animateMobile();
      setInterval(animateMobile, 20000); // Restart the animation every 20 seconds
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  return (
    <div className="section container">
      <div className="c-cover">
        <h1 className="c-cover__text">
          Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being Thoughts are being
        </h1>
        <h2 className="c-cover__text c-cover__text--highlight">
          思考照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创意照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;思考照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创意照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;思考照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创意照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;思考照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创意照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;思考照进现实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创意照进现实
        </h2>
      </div>
      <div className="c-content-lines">
        <div className="c-line top"></div>
        <div className="c-text-container">
          <div className="c-text-block">
            BRAND & DIGITAL<br />DESIGN AGENCY
          </div>
          <div className="c-text-block">EXPLORE</div>
          <div className="c-text-block">*品牌战略都是吹牛*？</div>
        </div>
        <div className="c-line bottom"></div>
      </div>
    </div>
  );
};

export default Page1;
