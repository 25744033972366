import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './ProjectPage.css';
import '../animations.css';
import axios from 'axios';
import ProjectPageInteractions from '../components/ProjectPageInteractions';
import SecurityFeatures from '../components/SecurityFeatures'; // 引入安全功能组件

const ProjectPage = ({ id, onBackClick }) => {
  const [animationClass, setAnimationClass] = useState('slide-down');
  const [project, setProject] = useState(null); // 初始状态设置为null
  const [loading, setLoading] = useState(true);

  const handleBackClick = () => {
    setAnimationClass('slide-up');
    setTimeout(() => {
      onBackClick();
    }, 500);
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      document.querySelectorAll('video').forEach(video => video.play());
      window.removeEventListener('touchstart', handleUserInteraction);
      window.removeEventListener('wheel', handleUserInteraction);
    };
  
    window.addEventListener('touchstart', handleUserInteraction);
    window.addEventListener('wheel', handleUserInteraction);
  
    return () => {
      window.removeEventListener('touchstart', handleUserInteraction);
      window.removeEventListener('wheel', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:3001/api/projects/${id}`)
      .then(response => {
        const projectData = response.data;

        // 处理project数据
        const sanitizedProjectData = {
          ...projectData,
          images: Array.isArray(projectData.images) ? projectData.images : [],
          images2: Array.isArray(projectData.images2) ? projectData.images2 : [],
          section1_backgroundImage: projectData.section1_backgroundImage || '',
          section4_backgroundImage: projectData.section4_backgroundImage || '',
          section1_text1: projectData.section1_text1 || '',
          section2_title: projectData.section2_title || '', 
          section2_challenge: projectData.section2_challenge || '',
          section2_challengeDescription: projectData.section2_challengeDescription || '',
          section2_customer: projectData.section2_customer || '',
          section2_customerDescription: projectData.section2_customerDescription || '',
          section3_strategy: projectData.section3_strategy || '',
          section3_strategyDetails: projectData.section3_strategyDetails || '',
          section3_brand: projectData.section3_brand || '',
          section3_brandDetails: projectData.section3_brandDetails || '',
          section4_storyTitle: projectData.section4_storyTitle || '',
          section4_storyContent: projectData.section4_storyContent || '',
          section5_designTitle: projectData.section5_designTitle || '',
          section5_designGoal: projectData.section5_designGoal || '',
          section6_logoTitle: projectData.section6_logoTitle || '',
          section6_logoDescription: projectData.section6_logoDescription || '',
          section6_image: projectData.section6_image || '',
          section7_ipTitle: projectData.section7_ipTitle || '',
          section7_ipDescription: projectData.section7_ipDescription || '',
          section7_image: projectData.section7_image || '',
          section8_colorSystemTitle: projectData.section8_colorSystemTitle || '',
          section8_colorSystemDescription: projectData.section8_colorSystemDescription || '',
          section8_image: projectData.section8_image || '',
          section9_fontTitle: projectData.section9_fontTitle || '',
          section9_fontDescription: projectData.section9_fontDescription || '',
          section9_image: projectData.section9_image || '',
          section10_typographyTitle: projectData.section10_typographyTitle || '',
          section10_typographyDescription: projectData.section10_typographyDescription || '',
          section10_image: projectData.section10_image || '',
          section11_iconTitle: projectData.section11_iconTitle || '',
          section11_iconDescription: projectData.section11_iconDescription || '',
          section11_image: projectData.section11_image || '',
          section12_iconTitle: projectData.section12_iconTitle || '',
          section12_iconDescription: projectData.section12_iconDescription || '',
          section12_image: projectData.section12_image || ''
        };

        if (sanitizedProjectData.title) {
          setProject(sanitizedProjectData);
        }

        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the project data!', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`project-page ${animationClass}`}>
      <SecurityFeatures /> {/* 引用安全功能组件 */}
      <ProjectPageInteractions handleBackClick={handleBackClick} />

      {project.section1_backgroundImage && (
      <div 
      id="div1" 
      className="textpage textpage1"
    >
      <div 
        className="textbackground1"
        style={{ backgroundImage: `url(${project.section1_backgroundImage})` }}
      >
        <div className='c-logo'>
        {project.logo && <img src={project.logo} alt='logo' />}
        </div>
      </div>
    </div>
      )}

      {project.section2_title && (
        <div id="div2" className="textpage textpage2">
          <p className="textpage2-text1">{project.section2_title}</p>
          <div className="text2row">
            <div className="text2title1">
              {project.section2_challenge && <p className="textpage2-text2">{project.section2_challenge}</p>}
              {project.section2_challengeDescription && <p className="textpage2-text3">{project.section2_challengeDescription}</p>}
              {project.section2_customer && <p className="textpage2-text4">{project.section2_customer}</p>}
              {project.section2_customerDescription && <p className="textpage2-text5">{project.section2_customerDescription}</p>}
            </div>
            <div className="text2title2">
              {project.section3_strategy && <p className="textpage2-text6">{project.section3_strategy}</p>}
              {project.section3_strategyDetails && <p className="textpage2-text7" dangerouslySetInnerHTML={{ __html: project.section3_strategyDetails }}></p>}
              {project.section3_brand && <p className="textpage2-text8">{project.section3_brand}</p>}
              {project.section3_brandDetails && <p className="textpage2-text9" dangerouslySetInnerHTML={{ __html: project.section3_brandDetails }}></p>}
            </div>
          </div>
        </div>
      )}

      {project.section4_backgroundImage && (
      <div id="div3" className="textpage3">
        <div className='c-images'>
          <img src={project.section4_backgroundImage} alt='section4 background' />
          {project.section4_storyTitle && <p className="textpage3-text1">{project.section4_storyTitle}</p>}
          {project.section4_storyContent && <p className="textpage3-text2" dangerouslySetInnerHTML={{ __html: project.section4_storyContent }}></p>}
        </div>
      </div>
      )}

      {project.section5_designTitle && (
        <div id="div4" className="textpage textpage4">
          <p className="textpage4-text1">{project.section5_designTitle}</p>
          {project.section5_designGoal && <p className="textpage4-text2" dangerouslySetInnerHTML={{ __html: project.section5_designGoal }}></p>}
        </div>
      )}

      {project.section6_logoTitle && (
        <div id="div5" className="textpage textpage5">
          <div className='introduce'>
            <div className="textR">
              <p className="textpage5-text1">{project.section6_logoTitle}</p>
              {project.section6_logoDescription && <p className="textpage5-text2" dangerouslySetInnerHTML={{ __html: project.section6_logoDescription }}></p>}
            </div>
            {project.section6_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section6_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

    {project.images?.length > 0 && project.images.map((file, index) => {
      const isVideo = file.endsWith('.mp4');
      return (
        <div className='c-images' key={index}>
          {isVideo ? (
            <div onClick={(e) => e.currentTarget.querySelector('video').play()}>
              <video playsInline loop muted preload="auto">
                <source src={file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="play-button-overlay" style={{display: 'none'}}>点击播放</div>
            </div>
          ) : (
            <img src={file} alt={`chatu${index + 1}`} />
          )}
        </div>
      );
    })}

      {project.section7_ipTitle && (
        <div id="div6" className="textpage textpage6">
          <div className='introduce'>
            <div className="textR">
              <p className="textpage6-text1">{project.section7_ipTitle}</p>
              {project.section7_ipDescription && <p className="textpage6-text2" dangerouslySetInnerHTML={{ __html: project.section7_ipDescription }}></p>}
            </div>
            {project.section7_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section7_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

      {project.section8_colorSystemTitle && (
        <div id="div7" className="textpage textpage7">
          <div className='introduce'>
            <div className="textR">
            <p className="textpage7-text1" dangerouslySetInnerHTML={{ __html: project.section8_colorSystemTitle }}></p>
              {project.section8_colorSystemDescription && <p className="textpage7-text2" dangerouslySetInnerHTML={{ __html: project.section8_colorSystemDescription }}></p>}
            </div>
            {project.section8_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section8_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

      {project.section9_fontTitle && (
        <div id="div8" className="textpage textpage8">
          <div className='introduce'>
            <div className="textR">
              <p className="textpage8-text1" dangerouslySetInnerHTML={{ __html: project.section9_fontTitle }}></p>
              {project.section9_fontDescription && <p className="textpage8-text2" dangerouslySetInnerHTML={{ __html: project.section9_fontDescription }}></p>}
            </div>
            {project.section9_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section9_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

      {project.section10_typographyTitle && (
        <div id="div9" className="textpage textpage9">
          <div className='introduce'>
            <div className="textR">
               <p className="textpage9-text1" dangerouslySetInnerHTML={{ __html: project.section10_typographyTitle }}></p>
              {project.section10_typographyDescription && <p className="textpage9-text2" dangerouslySetInnerHTML={{ __html: project.section10_typographyDescription }}></p>}
            </div>
            {project.section10_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section10_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

      {project.section11_iconTitle && (
        <div id="div10" className="textpage textpage10">
          <div className='introduce'>
            <div className="textR">
             <p className="textpage10-text1" dangerouslySetInnerHTML={{ __html: project.section11_iconTitle }}></p>
              {project.section11_iconDescription && <p className="textpage10-text2" dangerouslySetInnerHTML={{ __html: project.section11_iconDescription }}></p>}
            </div>
            {project.section11_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section11_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

      {project.section12_iconTitle && (
        <div id="div11" className="textpage textpage11">
          <div className='introduce'>
            <div className="textR">
             <p className="textpage11-text1" dangerouslySetInnerHTML={{ __html: project.section12_iconTitle }}></p>
              {project.section12_iconDescription && <p className="textpage11-text2" dangerouslySetInnerHTML={{ __html: project.section12_iconDescription }}></p>}
            </div>
            {project.section12_image && (
              <div 
                className='images' 
                style={{ backgroundImage: `url(${project.section12_image})` }}
              ></div>
            )}
          </div>
        </div>
      )}

    {project.images2?.length > 0 && project.images2.map((file, index) => {
      const isVideo = file.endsWith('.mp4');
      return (
        <div className='c-images' key={index}>
          {isVideo ? (
            <div onClick={(e) => e.currentTarget.querySelector('video').play()}>
              <video playsInline loop muted preload="auto">
                <source src={file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="play-button-overlay" style={{display: 'none'}}>点击播放</div>
            </div>
          ) : (
            <img src={file} alt={`chatu${index + 1}`} />
          )}
        </div>
      );
    })}
    </div>,
    document.body
  );
};

export default ProjectPage;
