// src/components/ProjectPageInteractions.js
import React, { useEffect } from 'react';

const ProjectPageInteractions = ({ handleBackClick }) => {

  // 第一个useEffect钩子将handleBackClick函数绑定到window对象，并在组件卸载时将其移除
  useEffect(() => {
    window.handleBackClick = handleBackClick;
    if (handleBackClick) {
      window.fullpage_api.setAllowScrolling(true);
    } else {
      window.fullpage_api.setAllowScrolling(true);
    }
    
    return () => {
      window.handleBackClick = null;
      window.fullpage_api.setAllowScrolling(true);
    };
  }, [handleBackClick]);
  
  // 第二个useEffect钩子监听触摸事件，实现触摸滑动调用handleBackClick
  useEffect(() => {
    let touchStartY = 0;
    let touchEndY = 0;
    const screenHeight = window.innerHeight; // 获取屏幕高度
    const swipeUpThreshold = screenHeight * 2; // 从下往上滑动的距离阈值为屏幕高度的30%
    const swipeDownThreshold = screenHeight * 0.20; // 从上往下滑动的距离阈值为屏幕高度的50%

    const handleTouchStart = (e) => {
      touchStartY = e.changedTouches[0].screenY; // 获取触摸开始时的Y坐标
    };

    const handleTouchMove = (e) => {
      touchEndY = e.changedTouches[0].screenY; // 获取触摸移动时的Y坐标
    };

    const handleTouchEnd = () => {
      const swipeDistance = touchStartY - touchEndY;
      
      // 判断滑动方向及距离，分别处理上下滑动
      if (swipeDistance > 0 && Math.abs(swipeDistance) > swipeUpThreshold) {
        // 从下往上滑动，滑动距离大于阈值，触发关闭
        handleBackClick();
      } else if (swipeDistance < 0 && Math.abs(swipeDistance) > swipeDownThreshold) {
        // 从上往下滑动，滑动距离大于阈值，触发关闭
        handleBackClick();
      }
    };

    const projectPageElement = document.querySelector('.project-page');
    if (projectPageElement) {
      projectPageElement.addEventListener('touchstart', handleTouchStart, { passive: false });
      projectPageElement.addEventListener('touchmove', handleTouchMove, { passive: false });
      projectPageElement.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      if (projectPageElement) {
        projectPageElement.removeEventListener('touchstart', handleTouchStart);
        projectPageElement.removeEventListener('touchmove', handleTouchMove);
        projectPageElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [handleBackClick]);

  return null;
};

export default ProjectPageInteractions;
