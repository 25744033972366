// src/LottieAnimation.js
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import lottie from 'lottie-web';
import animationData from './path/to/your/lottie2.json';

const LottieAnimation = forwardRef(({ loop = false, play = true, width = 150, height = 150 }, ref) => {
  const containerRef = useRef(null);
  const [animation, setAnimation] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [hasTriggered, setHasTriggered] = useState(false); // 新增状态变量，记录是否触发过

  useEffect(() => {
    // 加载动画
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animationData
    });

    setAnimation(anim);

    if (ref) {
      ref.current = containerRef.current;
    }

    // 卸载动画时销毁
    return () => anim.destroy();
  }, []);

  useEffect(() => {
    if (animation) {
      const handleMouseEnter = () => {
        setIsMouseOver(true);
        animation.setDirection(-1); // 设置动画反向
        animation.play();
      };

      const handleMouseLeave = () => {
        setIsMouseOver(false);
        animation.setDirection(1); // 设置动画正向
        animation.play();
      };

      const handleAnimationComplete = () => {
        const container = containerRef.current;
        if (!isMouseOver) {
          animation.pause(); // 暂停动画
          if (!hasTriggered) { // 检查是否已经触发过
            if (isMobileDevice()) { // 检查是否为移动设备
              container.style.transition = 'all 1s ease';
              container.style.opacity = '0'; // 设置透明度
            } else {
              container.style.transition = 'all 1s ease';
              container.style.top = '10%';
              container.style.left = '88%';
              container.style.transform = 'translate(0, 0)'; // 重置位置
            }
            setHasTriggered(true); // 设置为已触发
          }
        }
      };

      const container = containerRef.current;
      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
      animation.addEventListener('complete', handleAnimationComplete);

      return () => {
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
        animation.removeEventListener('complete', handleAnimationComplete);
      };
    }
  }, [animation, isMouseOver, hasTriggered]);

  const isMobileDevice = () => {
    // 检查设备是否为移动设备
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  return (
    <div
      ref={containerRef}
      id="logo-animation"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '170px',
        height: '170px',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'auto',
        transition: 'all 0.5s ease',
        zIndex: 10,
      }}
    />
  );
});

export default LottieAnimation;
