// src/FullpageWrapper.js
import React, { useEffect, useRef } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import './FullpageWrapper.css';

const FullpageWrapper = ({ lottieRef }) => {
  const containerRef = useRef(null);
  const hoverToggleCleanup = useRef(null); // 用于存储清理函数

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.classList.add('page-enter');
    }
  }, []);

  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  const setupMobileHoverToggle = () => {
    if (isMobileDevice()) {
      const handleTouch = () => {
        const textContainer = document.querySelector('.text-container');
        if (textContainer) {
          textContainer.classList.toggle('hover');
        }
      };

      document.addEventListener('touchstart', handleTouch);

      // 返回清理函数
      return () => {
        document.removeEventListener('touchstart', handleTouch);
      };
    }
    return null;
  };

  return (
    <div ref={containerRef}>
      <ReactFullpage
        navigation
        sectionsColor={['#fff', '#fff', 'black', '#435b71', '#e5e5e5']}
        controlArrows={true}
        afterLoad={(origin, destination, direction) => {
          // 当加载Page2时设置监听器
          if (destination.index === 1) {
            hoverToggleCleanup.current = setupMobileHoverToggle();
          }
        }}
        onLeave={(origin, destination, direction) => {
          const lottieContainer = lottieRef.current;

          // 离开Page2时移除监听器
          if (origin.index === 1 && hoverToggleCleanup.current) {
            hoverToggleCleanup.current(); // 移除事件监听器
            hoverToggleCleanup.current = null; // 清理引用
          }

          if (!isMobileDevice()) {
            const navbar = document.querySelector('.navbar');
            const links = document.querySelectorAll('.navbar-content a');

            if (lottieContainer) {
              if (destination.index === 0) {
                navbar.style.backgroundColor = 'white';
                links.forEach(link => link.style.color = 'black');
                lottieContainer.style.top = '10%';
                lottieContainer.style.left = '88%';
                lottieContainer.style.width = '150px';
                lottieContainer.style.height = '150px';
                lottieContainer.style.opacity = '1';
              } else if (destination.index === 1) {
                navbar.style.backgroundColor = '#002FA7';
                links.forEach(link => link.style.color = 'white');
                lottieContainer.style.top = '60%';
                lottieContainer.style.left = '80%';
                lottieContainer.style.width = '250px';
                lottieContainer.style.height = '250px';
                lottieContainer.style.transition = 'all 1s ease';
                lottieContainer.style.opacity = '1';
              } else if (destination.index === 2) {
                navbar.style.backgroundColor = '#002FA7';
                links.forEach(link => link.style.color = 'white');
                lottieContainer.style.top = '60%';
                lottieContainer.style.left = '80%';
                lottieContainer.style.width = '250px';
                lottieContainer.style.height = '250px';
                lottieContainer.style.transition = 'all 1s ease';
                lottieContainer.style.opacity = '1';
              } else {
                navbar.style.backgroundColor = '#002FA7';
                links.forEach(link => link.style.color = 'white');
                lottieContainer.style.top = '70%';
                lottieContainer.style.left = '85%';
                lottieContainer.style.width = '150px';
                lottieContainer.style.height = '150px';
                lottieContainer.style.opacity = '1';
              }
            }
          } else if (lottieContainer) {
            // Mobile device logic for lottieContainer
            lottieContainer.style.top = '0%';
            lottieContainer.style.left = '0%';
            lottieContainer.style.width = '0px';
            lottieContainer.style.height = '0px';
            lottieContainer.style.opacity = '0';
          }
        }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Page1 />
              <Page2 />
              <Page3 />
              <Page4 />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
};

export default FullpageWrapper;
